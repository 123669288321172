module.exports = {
  sanity: {
    projectId:
      process.env.GATSBY_SANITY_PROJECT_ID || "3ub3m275",
    dataset: process.env.GATSBY_SANITY_DATASET || "production",
  },
};


// Tokens
// Tokens are used to authenticate apps and scripts to access project data.

//GraphQL API skyv138m7TkXnT8rFivbJoSMZBPjmPZK3jcdCGKdSokNLMjgtBkBhF4YAnLrSngL4twMkubyPgWu9fvh4SnuaHT6jHBJQTD31zNfca2hiOuTlHOtiZyNapXmecjC4DQsGStoXMLopvkqrA9VvqKWS1i6NDk88q9oxH1V23XwCZL8T81B7KHW